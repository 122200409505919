import { addYears, getYear, isBefore, isSameDay, subDays } from "date-fns"
import { getLicensee, isNewLicensee } from "./helpers"
import {
  initialLicenseFee,
  licenseTypeChangeFee,
  licenseTransferFee,
  licenseReinstatementFee,
  renewalFee
} from "../../../data/fees"

const getNextRenewalDate = () => {
  const today = new Date()
  //today.setHours(0, 0, 0, 0) // This line allows new signups on June 30th of odd numbered years. Remove to disallow signups on June 30th.
  const currentYear = getYear(today)
  let renewalDate = new Date(currentYear, 5, 30)
  while (isBefore(renewalDate, today) || getYear(renewalDate) % 2 === 0) {
      renewalDate = addYears(renewalDate, 1)
  }
  return renewalDate
}

export const getFees = referenceNumber => {
  let fees = []
  const today = new Date()
  const renewalDate = getNextRenewalDate()
  if (isNewLicensee(referenceNumber)) {
    fees.push(initialLicenseFee)
    if (today > subDays(renewalDate, 89)) {
      fees.push(renewalFee)
    }
  } else {
  const { licenseStatus, licenseType, reinstateBy, licenseExpirationDate } = getLicensee(referenceNumber)
  const reinstatementDate = new Date(reinstateBy)
  if (today > subDays(renewalDate, 89)) {
    if (licenseStatus !== "ACTIVELY LICENSED") {
      fees.push(renewalFee)
    } else if (isSameDay(licenseExpirationDate, renewalDate)) {
      fees.push(renewalFee)
    }
  }


    if (isSameDay(reinstatementDate, renewalDate)) {
      fees.push(licenseReinstatementFee)
    } else if (licenseType === "SALESPERSON (REFERRAL)") {
      fees.push(licenseTransferFee)
    } else {
      fees.push(licenseTransferFee)
      fees.push(licenseTypeChangeFee)
    }
    // Ineligible
    if (isBefore(reinstatementDate, renewalDate)) {
      fees = []
    }
  }
  return fees
}
