const plans = [
  /*{
    id: "starter-plan",
    name: "Starter",
    price: "$90 / year",
    benefits: [
      "50 / 50 commission split",
      "Most Affordable"
    ]
  },
  {
    id: "essentials-plan",
    name: "Essentials",
    price: "$140 / year",
    benefits: [
      "70 / 30 commission split",
      "Most Popular"
    ]
  },
  {
    id: "professional-plan",
    name: "Pro",
    price: "$295 / year",
    benefits: [
      "90 / 10 commission split",
      "Best for Top Producers"
    ]
  },*/
  {
    id: "core-plan",
    name: "Billed Yearly",
    price: "$119 / year",
    amount: "Pay $119 Today",
    benefits: [
      "KEEP 100% OF YOUR COMMISSION",
      "$295 FEE PER TRANSACTION"
    ],
    recommended: true
  },
  {
    id: "core-plan-2-year",
    name: "Billed Every 2 Years",
    price: "$99 / year",
    amount: "Pay $198 Today",      
    benefits: [
      "KEEP 100% OF YOUR COMMISSION",
      "$295 FEE PER TRANSACTION"
    ]
  }
]

export default plans
