import React, { Fragment } from "react"
import Checkbox from "../../core/Checkbox/"
import Message from "../../core/Message/"
import { getFees } from "./fees"
import { getFields } from "./fields"
import plans from "../../../data/plans"
import "./Review.css"

const Review = props => {
  const fields = getFields(props.values)
  const fees = getFees(props.values.referenceNumber)
  const plan = plans.find(p => p.name === props.values.plan)

  const renderInfo = () => (
    <table className="info">
      <tbody>
        {fields.map(field => renderField(field))}
      </tbody>
    </table>
  )

  const renderField = field => (
    <tr key={field.name}>
      <th>
        <dl>
          <dt>{field.name}</dt>
          <dd>{field.description}</dd>
        </dl>
      </th>
      <td>{field.value}</td>
    </tr>
  )

  const renderFees = () => (
    <table className="fees">
      <tbody>
        <tr className="primary">
          <th>{plan.name}</th>
          <td>{plan.price}<br /><span style={{ color: 'darkgrey', fontSize: '70%' }}>{plan.amount} (+ state fees in blue)</span></td>
        </tr>
        {fees.map(fee => renderFee(fee))}
      </tbody>
    </table>
  )

  const renderFee = fee => (
    <tr key={fee.name}>
      <th>{fee.name}</th>
      <td>{fee.price}</td>
    </tr>
  )

  return (
    <fieldset className="step">
      <h2 className="title">Please review</h2>
      <p className="subtitle">
        Errors? Please click “Previous” to make changes or launch a <a href="https://join.generalreferral.com/#hs-chat-open">live chat</a>.
      </p>
      {
        fees.length === 0 &&
        <Message type="alert">
          Our records show that you can no longer reinstate your license.
          If you feel this was an error, please try again or <a href="https://join.generalreferral.com/#hs-chat-open">get in touch</a>!
        </Message>
      }
      {
        fees.length > 0 &&
        <Fragment>
          <div className="results">
            {renderInfo()}
            {renderFees()}
          </div>
          <div className="form-group">
            <Checkbox
              label={
                <label>
                I agree to all benefits and <a href="https://www.generalreferral.com/nj-ra-restrictions" target="_blank" rel="noopener 
                noreferrer">restrictions</a> conferred on me by the State of New Jersey upon 
                transferring my license to General Referral, a real estate referral company.
                </label>
              }
              name="termsAndConditions"
              value={props.values.termsAndConditions}
              invalid={props.errors.termsAndConditions}
              outlined
            />
          </div>
        </Fragment>
      }
    </fieldset>
  )
}

export default Review
